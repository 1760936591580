.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.VIpgJd-ZVi9od-ORHb img {
  border: none;
  display: none !important;
}
.lazyLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}
.lazyLoader img {
  width: auto;
  height: 55px;
  margin-left: 25px;
  margin-bottom: 0px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 1.2s infinite;
}
.small-rating .fa-star {
  font-size: 16px;
  color: #072f57;
}
.paginationBtn {
  border: 1px solid rgba(7, 47, 87, 1);
  padding: 8px 10px;
  background-color: #fff;
  color: #000000;
  border-radius: 5px;
  margin-right: 5px;
}
.paginationBtn:hover {
  border: 1px solid rgba(7, 47, 87, 1);
  padding: 8px 10px;
  background-color: rgba(7, 47, 87, 1);
  color: white;
  border-radius: 5px;
  margin-right: 5px;
}
.review-container {
  width: 75%;
}
@media only screen and (max-width: 425px) {
  .review-container {
    width: auto;
  }
}
.commonBtn {
  border: 1px solid rgba(7, 47, 87, 1);
  padding: 8px 10px;
  background-color: rgba(7, 47, 87, 1);
  color: #ffffff;
  border-radius: 5px;
  margin-right: 5px;
}
.commonBtn:hover {
  border: 1px solid rgba(7, 47, 87, 1);
  padding: 8px 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 5px;
  margin-right: 5px;
  transition: all ease-out 0.3s;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
